import React from "react";
import useStore from "../state/store";
import Run from "./Run";

function Log(props) {
  const logs = useStore(state => state.log);

  return (
    <div className={`${props.className} w-full flex flex-col overflow-y-scroll`}>
      {logs.slice(0).reverse().map((log, index) => (<Run key={index} data={log} />))}
    </div>
  )
}

export default Log