import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Home from './components/Home';
import AddRun from './components/AddRun';

function App() {
  return (
    <div className="App flex justify-center">
      <Router>
        <Routes>
          <Route index element={<Home />} />
          <Route path="add" element={<AddRun />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
