import React from 'react'
import { useNavigate } from 'react-router'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BackButton(props) {
  const history = useNavigate()

  return(
    <button onClick={() => props.to ? history.push(props.to) : history(-1)} className={`${props?.className || ''} h-10 flex items-center justify-center mr-2 hover:bg-gray-300 rounded w-10`}><ArrowBackIcon /></button>
  )
}