import React from "react";
import useStore from "../state/store";

function Counter(props) {
  const total = useStore(state => state.getTotalDistance());

  return (
    <div className={`${props.className} flex flex-col w-full`}>
      <div className="">
        You ran
      </div>
      <div className="flex-1 text-5xl font-semibold">
        {parseFloat(total.toFixed(2))} km
      </div>
      <div>
        this year
      </div>
    </div>
  )
}

export default Counter