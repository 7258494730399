import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import useStore from "../state/store";

function Run(props) {

  const removeItem = useStore(state => state.removeItem)

  return (
    <div className="w-full mt-2 flex">
      <div>{new Date(props.data.date).toLocaleDateString()}</div>
      <div className="flex-1 text-xl">{props.data.distance} km</div>
      <button className="ml-2 shadow border rounded w-12" onClick={() => {removeItem(props.data.id)}}><CloseIcon /></button>
    </div>
  )
}

export default Run