import create from "zustand"
import {persist} from 'zustand/middleware'

const createLogSlice = (set, get) => ({
  log: [],

  // method to return the total distance of all log items for this year
  getTotalDistance: (year = new Date()) => {
    const log = get().log
    return log.reduce((total, item) => { 
      if(new Date(item.date).getFullYear() === year.getFullYear()) {
        return Number(total) + Number(item.distance)
      }else{
        return Number(total)
      }
    }, 0.0)
  },

  addItem: (item) => {
    set({log: [...get().log, item]})
  },

  removeItem: (id) => {
    const log = get().log.filter(item => item.id !== id)
    set({log})
  }
})

const useStore = create(persist((set, get) => ({
  ...createLogSlice(set, get),
}), {
  name: 'log-store',
  getStorage: () => localStorage,
}))

export default useStore