import React, {useState, useCallback} from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../state/store";
import BackButton from "./BackButton";
import { v4 as uuidv4 } from 'uuid';

function AddRun(props) {
  const addItem = useStore(state => state.addItem);
  const [distance, setDistance] = useState(0);
  const [date] = useState(new Date());
  const navigate = useNavigate()

  const saverun = useCallback(() => {
      addItem({
        id: uuidv4(),
        date: date,
        distance: distance,
      })
      navigate("/")
  },[date, distance, addItem, navigate])

  return (
    <div className="flex flex-col p-4 w-screen lg:w-1/2 h-screen justify-start text-gray-700">
      <div className="flex justify-between">
        <div className="flex-1"><BackButton /></div>
        <div className="self-center font-bold">New Run</div>
        <div className="flex-1"/>
      </div>
      <div className="flex mt-4 justify-center text-2xl">
        {date.toLocaleDateString('de-DE', {})}
      </div>
      <div className="flex mt-4">
        <div className="font-semibold text-right self-center w-1/2 px-2">Distance (km):</div>
        <input
          className="self-start shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="search" type="number" value={distance} onChange={event => setDistance(event.target.value)} autoFocus={true}/>
      </div>

      <button
        onClick={saverun}
        className="mt-4 flex w-full items-center justify-center h-10 px-4 text-sm font-medium rounded bg-gray-200 hover:bg-gray-300">Add
      </button>
    </div>
  )
}

export default AddRun