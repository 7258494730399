import React from "react";
import Counter from "./Counter";
import Log from "./Log";
import { Link } from "react-router-dom"

export default function Home() {
  return (
    <div className="flex flex-col p-4 w-screen lg:w-1/2 h-screen justify-start text-gray-700">
      <Link to="/add" >
        <button
          className="flex w-full items-center justify-center h-10 px-4 text-sm font-medium rounded bg-gray-200 hover:bg-gray-300 focus:outline-none">Add
        </button>
      </Link>
      <Counter className="mt-4" />
      <Log className="mt-4 flex-1" />
    </div>
  )
}